<template>
  <div class="faq-content tw-bg-white tw-overflow-y-auto tw-p-4 tw-pb-16">
    <div class="faq-content__wrapper">
      <h2
        class="faq-content__title tw-mb-6 tw-text-xl"
        v-text="question.title"
      />
      <div
        v-for=" content in question.content"
        :key="content"
        class="faq-content__text"
        v-markdown="content"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - faqContent
   * @param {object} question
   */
  export default {
    name: 'FaqContent',
    props: {
      question: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .faq-content {
    &__title {
      border-bottom: 1px solid $secondary;

      @media only screen and (max-width: 857px) {
        display: none;
      }
    }

    &__text {
      color: $secondary-text;
    }

    &__wrapper {
      width: 420px;
      margin: 170px auto 0 auto;

      @media only screen and (max-width: 857px) {
        width: 100%;
        padding: 0 16px;
        margin-top: 0;
      }
    }
  }

</style>

<style lang="scss">

  .faq-content__text a {
    color: $info;
    text-decoration: underline;
  }

</style>
