<template>
  <div class="faq-side tw-flex flex-fixed">
    <div class="tw-flex tw-flex-col faq-side__hero">
      <div class="faq-side__hero__content px-3">
        <picture>
          <source
            srcset="@/assets/img/logo-icon-white.webp"
            type="image/webp"
          >
          <img
            width="50"
            height="50"
            src="@/assets/img/logo-icon-white.png"
            class="faq-side__hero__content__logo"
            alt=""
          >
        </picture>

        <h1
          class="tw-font-light"
          data-test="title"
        >
          {{ $trans('faq.title') }}
        </h1>
        <p
          v-text="$trans('faq.paragraphs.introduction')"
          class="fs-14"
          data-test="description"
        />
      </div>
    </div>
    <div class="faq-side__menu tw-flex tw-flex-col">
      <faq-contact />
      <faq-documents
        class="mb-5"
      />
      <faq-navigation
        :questions="questions"
        :question="question"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import FaqContact from './_subs/FaqContact/index.vue'
  import FaqDocuments from './_subs/FaqDocuments/index.vue'
  import FaqNavigation from './_subs/FaqNavigation/index.vue'

  /**
   * @module component - FaqSide
   * @params {Array} questions
   * @params {string} question
   */
  export default defineComponent({
    name: 'FaqSide',
    props: {
      questions: {
        type: Array,
        required: true
      },
      question: {
        type: String,
        required: true
      }
    },
    components: {
      FaqContact,
      FaqDocuments,
      FaqNavigation
    }
  })
</script>

<style lang="scss" scoped>

  .faq-side {
    height: 100%;

    &__hero {
      position: relative;
      height: 100%;
      width: 380px;
      background-image: url('~@/assets/img/backgrounds/faq.jpg');
      background-size: cover;
      background-position: center;

      &::before {
        position: absolute;
        content: '';
        background-color: rgba(black, 0.6);
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      &__content {
        margin: auto;
        color: white;
        text-align: center;
        z-index: 1;

        &__logo {
          margin-bottom: 32px;

          @media only screen and (max-width: $breakpoint-tablet) {
            display: none;
          }
        }

        h1 {
          font-size: 26px;
          margin-bottom: 24px;
        }

        a {
          color: white;
        }
      }
    }

    &__menu {
      width: 380px;
      z-index: 1;
      background-color: white;
      box-shadow: 0 10px 7px 0 rgba(0, 0, 0, 0.1);
      padding-top: 100px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    @media only screen and (max-width: 1225px) {
      flex-direction: column;

      &__hero {
        height: 250px;
      }

      &__menu {
        padding-top: 32px;
        flex: 1;
      }
    }

    @media only screen and (max-width: 857px) {
      height: auto;

      &__hero {
        height: 200px;
      }

      &__hero,
      &__menu {
        width: 100%;
      }

      &__menu {
        box-shadow: none;
      }
    }
  }

</style>
